<template>
  <div>
    <div
      class="min-h-screen container mx-auto flex flex-col gap-y-5 justify-center items-center font-EffraR bg-white py-5"
    >
      <policies></policies>
    </div>

    <dok-footer></dok-footer>
  </div>
</template>
<script>
const policies = () => import("./policies.vue");
const dokFooter = () => import("@/views/home/layouts/footer");

export default {
  components: {
    dokFooter,
    policies
  }
};
</script>
<style>
h1,
h2 {
  display: block !important;
  margin: 15px auto 5px;
}
</style>
